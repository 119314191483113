.main_image {
  position: relative;
  text-align: left;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.mainPageImages {
  background-image: url("https://res.cloudinary.com/datcltouj/image/upload/q_auto:eco/v1566577213/jhkj9vmgrsuktmrw1y6c.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  z-index: -1;
}
.SD {
  position: absolute;
  font-size: 3.5rem;
  top: 0;
  left: 0;
  /* margin: 0; */
  margin-top: 6%;
  margin-left: 8%;
  width: 75%;
}
.sub_1 {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 10%;
  margin-left: 8%;
  font-size: 1.75rem;
}
.project_gallery_text {
 color: white;
}
.mission_statement {
  color: rgb(219, 5, 5);
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  margin: 15px;
}
hr {
  margin: 40px;
  border-top: 3px solid rgb(25, 25, 189);
}
.tagline {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  margin: 0;
}
.description {
  padding: 0 8vw;
  font-family: 'Montserrat', sans-serif;
}
.icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: space-around;
}
.icon_pic {
  border: 3px solid black;
  width: 40vw;
  margin: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icons img {
  width: 200px;
  height: 200px;
}
.icons h3 {
  margin: 0;
  color: black;
  font-family: 'Montserrat', sans-serif;
}
.contact {
  /* margin: 40px 0; */
  position: relative;
  text-align: left;
  color: white;
  font-family: 'Montserrat', sans-serif;
}
.mainPageImages_1 {
  background-image: url("https://res.cloudinary.com/datcltouj/image/upload/v1566836472/gimrzsuvathl7rcifzgi.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  z-index: -1;
}
.contact h1 {
  position: absolute;
  font-size: 3.5rem;
  top: 0;
  left: 0;
  margin-top: 10%;
  margin-left: 8%;
}
.contact p {
  border: 9px solid rgb(219, 5, 5);
  color: rgb(219, 5, 5);
  position: absolute;
  font-size: 2rem;
  top: 0;
  left: 0;
  margin-bottom: 30%;
  margin-top: 20%;
  margin-left: 15%;
  cursor: pointer;
  padding: 10px;
}
@media (max-width: 1400px) {
  .contact h1 {
    margin-top: 30vh;
    font-size: 3rem;
  }
  .contact p {
    margin-bottom: 45vh;
  }
}
@media (max-width: 1200px) {
  .project_gallery {
    margin-bottom: 21vh;
  }
  .contact h1 {
    margin-top: 25vh;
    font-size: 2.5rem;
  }
  .contact p {
    margin-bottom: 40vh;
    font-size: 1.5rem;
  }
}
@media (max-width: 1050px) {
  .contact h1 {
    margin-top: 20vh;
  }
  .SD {
    /* font-size: 3rem; */
    /* margin-top: 14vh; */
  }
  .sub_1 {
    font-size: 1.5rem;
  }
}
@media (max-width: 1007px) {
  .SD {
    margin-top: 10vh;
    font-size: 3rem;
  }
  .sub_1 {
    margin-top: 17vh;
  }
  .project_gallery {
    margin-bottom: 18vh;
    font-size: 2rem;
  }
  .mission_statement {
    font-size: 3.5rem;
  }
}
@media (max-width: 950px) {
  .contact h1 {
    font-size: 2rem;
  }
  .contact p {
    margin-bottom: 30vh;
  }
}
@media (max-width: 820px) {
  .contact h1 {
    margin-top: 15vh;
    font-size: 1.6rem;
  }
  .project_gallery {
    font-size: 1.5rem;
  }
  .contact p {
    margin-bottom: 28vh;
    border: 8px solid rgb(219, 5, 5);
    font-size: 1.4rem;
  }
  .mainPageImages {
    /* background-size: auto; */
    background-position: center;
  }
  .mainPageImages_1 {
    background-position: center;
  }
  /* .main_image p {
        font-size: 1.5rem;
    } */
}
@media (max-width: 750px) {
  .SD {
    font-size: 2.5rem;
  }
  .sub_1 {
    margin-top: 16vh;
    font-size: 1.2rem;
  }
}
@media (max-width: 625px) {
  .SD {
    font-size: 2rem;
  }
  .sub_1 {
    margin-top: 15vh;
  }
  .icon_pic {
   padding: 0;
   margin: 5px;
  }
  .icon_pic img{
    height: 50%;
    width: 50%;
  }
}
@media (max-width: 500px) {
  .main_image {
    text-align: center;
  }
  .SD {
    display: inline-block;
    margin-left: 0;
    margin-top: 10vh;
    width: 100%;
  }
  .sub_1 {
    display: inline-block;
    margin-left: 7.4vw;
    margin-top: 15vh;
  }
  .project_gallery {
      display: inline-block;
  }
}
@media (max-width: 375px) {
  .SD {
    margin-top: 8%;
    line-height: 1;
  }
  .mainPageImages {
    text-align: center;
  }
  .sub_1 {
    margin-top: 26%;
    margin-left: 14%;
  }
  .icon_pic {

  }
}
