.Nav {
    /* max-width: 100vw; */
    height: 70px;
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: rgb(36, 33, 33);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 5;
    font-family: 'Montserrat', sans-serif;
}
.syndicate_lion {
    background-image: url('https://res.cloudinary.com/datcltouj/image/upload/v1566925028/rcl6z1llalr6gc8zwj9n.png');
    height: 60px;
    width: 60px;
    z-index: 10;
}
.syndicate_lion img {
    height: 100%;
    width: 100%;
    z-index: 10;
}

.Nav > i {
    color: white;
    font-size: 2rem;
}
.dropdown {
    opacity: .8;
    text-align: center;
    background-color:  rgb(36, 33, 33);
    transition: .4s;
    height: 300px;
    width: 200px;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 10;
}
.dropdown > .container {
    border-top: 5px solid red;
    opacity: .8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: 'Montserrat', sans-serif;
}
.hide {
    position: absolute;
    top: 70px;
    right: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}
li {
    margin: 14px;
    font-size: 1.5rem;
    list-style: none;
    color: white;
    cursor: pointer;
    transition: .4s;
    font-family: 'Montserrat', sans-serif;
}
a{
    text-decoration: none;
}
