* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;
  margin-top: 70px;
  width: 100vw;
}
/* .nav_offset {
  width: 100vw;
  height: 70px;
} */
button {
  height: 40px;
  width: 150px;
  background-color: rgb(122, 177, 240);
  margin: 5px;
  border-radius: 25px;
  box-shadow: 5px 5px 5px gray;
  outline: none;
}
input {
  border: 2px solid gray;
  margin: 5px;
  box-shadow: 5px 5px 5px gray;
  height: 30px;
  width: 150px;
  text-align: center;
  outline: none;
}
@media (max-width: 400px) {
  button {
    height: 30px;
    width: 100px;
  }
}

