.Suspension_room {
  text-align: center;
  color: black;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  width: 100%;
}
.todd-pic {
  background-image: url("https://res.cloudinary.com/datcltouj/image/upload/v1566577283/ijcja6ddezg3nloucbiw.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 75vh;
  opacity: 0.9;
  z-index: -1;
}
.Suspension h1 {
  position: absolute;
  font-size: 5rem;
  top: 0;
  left: 0;
  margin-top: 18%;
  margin-left: 18%;
}
.suspension-list li {
  color: black;
}
.suspension-info {
  display: flex;
  justify-content: space-around;
  width: 95vw;
  height: 40vh;
}
@keyframes suspension {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.suspension-pics {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  height: 300px;
  width: 225px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.suspension-pics img {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  position: absolute;
  animation-name: suspension;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 9s;
}
.s_pic_1 {
  animation-delay: 6s;
}
.s_pic_2 {
  animation-delay: 3s;
}
.s_pic_3 {
  animation-delay: 0s;
}
@media (max-width: 1100px) {
  .Suspension h1 {
    margin-top: 18%;
    margin-left: 7%;
  }
}
@media (max-width: 900px) {
  .todd-pic {
    background-position: center;
  }
}
@media (max-width: 500px) {
  .Suspension h1 {
    margin-top: 45%;
    margin-left: 3%;
    font-size: 3.5rem;
  }
  .s_pic_1 {
    height: 100px;
    width: 75px;
  }
  .s_pic_2 {
    height: 100px;
    width: 75px;
  }
  .s_pic_3 {
    height: 100px;
    width: 75px;
  }
  .s_pic_4 {
    height: 100px;
    width: 75px;
  }
}
@media (max-width: 350px) {
    .Suspension h1 {
        font-size: 3rem;
    }
}
