.ecu-tuning{
    text-align: center;
    color: black;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60vh;
    width: 100%;
    background-color: rgb(243, 243, 243);

}