.services-room {
  display: flex;
  flex-direction: column;
    position: relative;
    align-items: center;
    text-align: center;
    color: black;
    font-family: 'Montserrat', sans-serif;
  }
  .todd-pic {
    background-image: url("https://res.cloudinary.com/datcltouj/image/upload/v1566577283/ijcja6ddezg3nloucbiw.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 75vh;
    opacity: 0.9;
    z-index: -1;
  }
  hr{
    width: 90vw;
  }
  .services-statement{
    width: 90vw;
  }
  
  @media (max-width: 1100px) {
    .Suspension h1 {
      margin-top: 18%;
      margin-left: 7%;
    }
  }
  @media (max-width: 900px) {
    .todd-pic {
      background-position: center;
    }
  }
  @media (max-width: 500px) {
    .Suspension h1 {
      margin-top: 45%;
      margin-left: 3%;
      font-size: 3.5rem;
    }
    .s_pic_1 {
      height: 100px;
      width: 75px;
    }
    .s_pic_2 {
      height: 100px;
      width: 75px;
    }
    .s_pic_3 {
      height: 100px;
      width: 75px;
    }
    .s_pic_4 {
      height: 100px;
      width: 75px;
    }
  }
  @media (max-width: 350px) {
      .Suspension h1 {
          font-size: 3rem;
      }
  }
  